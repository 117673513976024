export function display_alert(resp) {
  var notice = resp.getResponseHeader("X-Flash-Notice");
  var error = resp.getResponseHeader("X-Flash-Error");
  var anchor = '<a name="alerts"></a>';

  if (notice) {
    $('.alerts').html(anchor + '<div data-alert class="callout success" role="alert">' + notice + '<a href="#" class="close-button">&times;</a></div>');
  } else if (error) {
    $('.alerts').html(anchor + '<div data-alert class="callout alert" role="alert">' + error + '<a href="#" class="close-button">&times;</a></div>');
  }
};